import React, { useEffect, useState } from 'react';
import './Popular.css';
import Item from '../Item/Item';

const Popular = () => {
  const [popularProducts, setPopularProducts] = useState([]);

  const apiUrl = process.env.REACT_APP_BACKEND_URL  || 'http://localhost:4000';

  useEffect(() => {
    fetch(`${apiUrl}/popularchoices`)
      .then((response) => response.json())
      .then((data) => setPopularProducts(data))
      .catch((error) => console.error('Error fetching popular products:', error));
  }, [apiUrl]);

  return (
    <div className='popular'>
      <h1>Popular Choices</h1>
      <hr />
      <div className="popular-item">
        {popularProducts.map((item, i) => (
          <Item
            key={i}
            id={item.id}
            name={item.name}
            main_image={item.main_image}
            new_price={item.new_price}
            old_price={item.old_price}
          />
        ))}
      </div>
    </div>
  );
};

export default Popular;
