import React, { useEffect, useState, useCallback } from 'react';
import './NewCollections.css';
import Item from '../Item/Item';

const NewCollections = () => {
  const [new_collection, setNew_collection] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';

  const fetchNewCollections = useCallback(async () => {
    try {
      const response = await fetch(`${backendUrl}/newcollections`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setNew_collection(data);
    } catch (error) {
      console.error("Error fetching new collections:", error);
    }
  }, [backendUrl]);

  useEffect(() => {
    fetchNewCollections();
  }, [fetchNewCollections]);

  return (
    <div className='new-collections' id="new-collections-section">
      <h1>NEW COLLECTIONS</h1>
      <hr />
      <div className="collections">
        {new_collection.length > 0 ? (
          new_collection.map((item) => (
            <Item
              key={item.id}
              id={item.id}
              name={item.name}
              main_image={item.main_image}
              new_price={item.new_price}
              old_price={item.old_price}
            />
          ))
        ) : (
          <p>No new products available.</p>
        )}
      </div>
    </div>
  );
};

export default NewCollections;
