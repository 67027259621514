import React, { useState, useContext} from 'react';
import './ProductDisplay.css';
import star_icon from "../Assets/star_icon.png";
import star_dull_icon from "../Assets/star_dull_icon.png";
import { ShopContext } from '../../context/ShopContext';

const ProductDisplay = (props) => {
    const { product } = props;
    const {addToCart} = useContext(ShopContext);
    const [activeImage, setActiveImage] = useState(''); // Track the currently opened image

    // Replicate the images to ensure exactly 4 are displayed
    const imagesToDisplay = product.other_images && product.other_images.length > 0
        ? [...product.other_images, ...Array(4 - product.other_images.length).fill(product.other_images[0])]
        : [];

    const handleImageClick = (imageSrc) => {
        setActiveImage(prev => prev === imageSrc ? '' : imageSrc);
    };

    return (
        <div className='productdisplay'>
            <div className="productdisplay-left">
                {imagesToDisplay.length > 0 && (
                    <div className="productdisplay-img-list">
                        {imagesToDisplay.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Detail view ${index + 1}`}
                                onClick={() => handleImageClick(image)}
                                className={`productdisplay-thumbnail ${activeImage === image ? 'active' : ''}`}
                            />
                        ))}
                    </div>
                )}
                <div className="productdisplay-img">
                    <img className='productdisplay-main-img' src={product.main_image} alt={product.name} />
                </div>
            </div>
            <div className="productdisplay-right">
                <h1>{product.name}</h1>
                <div className="productdisplay-right-star">
                    <img src={star_icon} alt="Filled star icon" />
                    <img src={star_icon} alt="Filled star icon" />
                    <img src={star_icon} alt="Filled star icon" />
                    <img src={star_icon} alt="Filled star icon" />
                    <img src={star_dull_icon} alt="Dull star icon" />
                    <p>122</p>
                </div>
                <div className="productdisplay-right-prices">
                    <div className="productdisplay-right-price-old">Ksh{product.old_price}</div>
                    <div className="productdisplay-right-price-new">Ksh{product.new_price}</div>
                </div>
                <div className="productdisplay-right-description">
                    {product.summary}
                </div>
                <button onClick={()=>{addToCart(product.id)}}>ADD TO CART</button>
                <p className='productdisplay-right-category'><span>Category :</span>{product.category}</p>
            </div>

            {/* Inline Image Display */}
            {activeImage && (
                <div className="productdisplay-overlay" onClick={() => setActiveImage('')}>
                    <div className="productdisplay-overlay-content" onClick={e => e.stopPropagation()}>
                        <img src={activeImage} alt={`Enlarged view`} className="productdisplay-overlay-image" />
                        
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductDisplay;
