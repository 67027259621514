import React, { useEffect, useState, useCallback } from 'react';
import './RelatedProducts.css';
import Item from '../Item/Item';

const RelatedProducts = () => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [error, setError] = useState(null);

  // Fetch related products
  const fetchRelatedProducts = useCallback(async () => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';
      const response = await fetch(`${backendUrl}/relatedproducts`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRelatedProducts(data);
    } catch (error) {
      console.error('Error fetching related products:', error);
      setError(error.message);
    }
  }, []);

  // Fetch data on component mount
  useEffect(() => {
    fetchRelatedProducts();
  }, [fetchRelatedProducts]);

  return (
    <div className='relatedproducts'>
      <h1>Related Products</h1>
      <hr/>
      {error && <p className='error-message'>{error}</p>}
      <div className="relatedproducts-item">
        {relatedProducts.length > 0 ? (
          relatedProducts.map((item) => (
            <Item 
              key={item.id} 
              id={item.id} 
              name={item.name} 
              main_image={item.main_image} 
              new_price={item.new_price} 
              old_price={item.old_price} 
            />
          ))
        ) : (
          <p>No related products available.</p>
        )}
      </div>
    </div>
  );
}

export default RelatedProducts;
